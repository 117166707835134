export const quiz_fr =  {
  "general": {
    "title": "Droit à l'application des droits de l'enfant ",
    "intro": "Comment les enfants peuvent-ils faire valoir leurs droits ? ",
    "finishButton": "Vérifier les réponses",
    "nrQuestions": 5,
    "maxPoints": 25,
    "resultTitle": "C'est fait!",
    "resultText": "Tu as répondu correctement à <correctQuestions> questions sur <questions> et tu as donc obtenu <points> de <pointsMax> points.",
    "button_back": "retour",
    "button_forward": "suivant"
  },
  "questions": [
    {
      "question": "au sein du conseil des élèves ou du conseil de classe ?",
      "picture": "klassenrat.png",
      "answers": [
        "oui",
        "non"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "au sein du parlement des enfants et des jeunes de la commune ?",
      "picture": "kinderparlament.png",
      "answers": [
        "oui",
        "non"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "en discutant avec chaque adulte ?",
      "picture": "erwachsene.png",
      "answers": [
        "oui",
        "non"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "lors d'une manifestation ?",
      "picture": "demonstration.png",
      "answers": [
        "oui",
        "non"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "avec une avocate ou un avocat ?",
      "picture": "anwalt.png",
      "answers": [
        "oui",
        "non"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
  ]
} 