export const quiz_fr =  {
  "general": {
    "title": "Droit de suivre une thérapie et de recevoir des soins psychologiques appropriés",
    "intro": "Résous le quiz !",
    "finishButton": "Vérifier les réponses",
    "nrQuestions": 4,
    "maxPoints": 20,
    "resultTitle": "C'est fait!",
    "resultText": "Tu as répondu correctement à <correctQuestions> questions sur <questions> et tu as donc obtenu <points> de <pointsMax> points.",
    "button_back": "retour",
    "button_forward": "suivant"
  },
  "questions": [
    {
      "question": "Qu'est-ce qui n'est pas une thérapie ?",
      "picture": "",
      "answers": [
        "Art-thérapie",
        "Psychothérapie",
        "Entraînement de football"
      ],
      "correctAnswer": [2],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "A qui un enfant parle-t-il en thérapie ?",
      "picture": "",
      "answers": [
        "avec une ou un psychologue",
        "avec une policière ou un policier",
        "avec une enseignante ou un enseignant"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Que peut faire un enfant en thérapie ?",
      "picture": "",
      "answers": [
        "jouer avec des amies ou des amis",
        "parler de ses sentiments",
        "faire ses devoirs"
      ],
      "correctAnswer": [1],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Comment une ou un thérapeute peut aider un enfant ?",
      "picture": "",
      "answers": [
        "elle ou il est à l'écoute",
        "elle ou il fait des cadeaux",
        "elle ou il l'accompagne en classe"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
  ]
}