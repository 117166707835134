import { image_importer } from '../../../helper/helper_functions';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../hooks/useUserContext';
import CustomQuiz from '../../games/CustomQuiz/CustomQuiz';
import { quiz_de } from './umsetzung/quiz_de';
import { quiz_fr } from './umsetzung/quiz_fr';
import { quiz_it } from './umsetzung/quiz_it';

export default function Umsetzung({ oldAndNewScore }) {
    const images = image_importer(require.context('../../../assets/games/beteiligungsrechte/umsetzung', false, /\.(png|jpe?g|jpg|svg)$/));
    const { games, updateGames, calculateScore, score } = useUserContext();
    const quiz_game = games.umsetzung;

    const { i18n } = useTranslation();
    let quiz = '';
    switch(i18n.language) {
        case 'de':
            quiz = quiz_de;
            break;
        case 'fr':
            quiz = quiz_fr;
            break;
        case 'it':
            quiz = quiz_it;
            break;
        default:
            quiz = quiz_de;
    };

    const setQuizResult = (points) => {
        const quiz_new = { ...quiz_game, state: 'done', points_achieved: points };
        const games_new = { ...games, umsetzung: quiz_new };
        updateGames(games_new);
        const current_game = "umsetzung";
        let total_score = calculateScore(current_game);
        total_score += points;
        const oldScore = score;
        const newScore = total_score;
        oldAndNewScore(oldScore, newScore);
    };

    return (
        <div>
            <CustomQuiz inputQuiz={quiz} finishFunction={setQuizResult} images={images}/>
        </div>
    )
}