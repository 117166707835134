//styles
import './App.css';

//import for Routing
import { BrowserRouter } from 'react-router-dom';

//components
import React, { Suspense, useEffect } from 'react';
import AnimatedRoutes from './components/AnimatedRoutes';
import { FadeInBGSound, FadeOutBGSound } from "./helper/helper_functions";
import { useTranslation } from 'react-i18next';
import { image_importer } from "./helper/helper_functions";

//import User-Daten
import { useUserContext } from "./hooks/useUserContext";

//import for update
import { clear_sw } from './helper/clear_cache';
import { update_v1 } from './helper/update';

//import für Notification
//import OneSignal from 'react-onesignal';

//Aufsetzen der Konfiguration und initilisieren für Notification (unterschiedliche appIDs für lokal und Deployment)
/*const runOneSignal = () => {
  let appId = ""
  if(window.location.hostname==='localhost' || window.location.hostname==='127.0.0.1') {
    appId = '379a2ea0-b549-4087-8016-631720669d93';
  } else {
    appId = 'db3fd654-8c75-49bf-abc0-58ff337e07d7';
  };

  OneSignal.init({ 
    appId: appId,
    safari_web_id: "web.onesignal.auto.2f682342-7506-4d13-96c3-4fd5fa35ae95",
    notifyButton: {
      enable: false,
    },
    allowLocalhostAsSecureOrigin: true,
    serviceWorkerPath:'push-onesignal/OneSignalSDKWorker.js',
    serviceWorkerParam: {
      scope: "/push-onesignal/"
    },
    welcomeNotification: {
      disable: true
    },
    promptOptions: {
      actionMessage: "Klicke auf 'Zulassen', um News zu erhalten.",
      slidedown: {
        prompts: [
          {
            type: "push",
            autoPrompt: false,
            text: {
              actionMessage: "Dürfen wir dir Neuigkeiten direkt anzeigen?",
              acceptButton: "Ja",
              cancelButton: "Nein"
            },
          }
        ]
      }
    }
  })
  //.then (() => {
  //  OneSignal.showSlidedownPrompt();
  //})
};*/

function App() {
  useEffect(() => {
    if(!JSON.parse(localStorage.getItem("version")) || JSON.parse(localStorage.getItem("version"))<3.5) {
      clear_sw(3.5);
    } else if(JSON.parse(localStorage.getItem("version"))<5) {
      update_v1(5);
    }
  },[]);

  window.addEventListener('touchstart', () => {
    document.getElementById('bgSound').muted = false;
    document.getElementById('bgSound').play();
  })

  const { t } = useTranslation();
  const general_images = image_importer(require.context('/src/assets/general', false, /\.(png|jpe?g|svg)$/));
  const { name, settings } = useUserContext();

  document.title = t('general.page_title');
  document.getElementsByTagName('meta')["description"].content = t('general.page_description');

  const startSound = async (audioSrc, changeSettings) => {
    if (name && name!=='') {
      const objectSound = document.getElementById('bgSound');
      const currentSound = objectSound.src.substring(objectSound.src.lastIndexOf("/")+1);
      const newSound = audioSrc.substring(12);
      if (changeSettings===true) {FadeInBGSound(objectSound, audioSrc)} else {
        if (currentSound!==newSound && currentSound!=='' && settings.sound==='soundOn') {await FadeOutBGSound(objectSound)};
        if (audioSrc!=='' && settings.sound==="soundOn" && currentSound!==newSound) {FadeInBGSound(objectSound, audioSrc)};
      }
    }
  };
  const delay = (ms) => new Promise(res => setTimeout(res, ms));
  const stopSound = async () => {
    const objectSound = document.getElementById('bgSound');
    if (name && name!=='') {FadeOutBGSound(objectSound)};
    await delay(300);
    objectSound.pause();
    objectSound.src = '';
  };
  /*
  if ('storage' in navigator && 'estimate' in navigator.storage) { 
    navigator.storage.estimate() 
        .then(function(estimate){ 
            console.log(`Using ${estimate.usage} out of ${estimate.quota} bytes.`); 
        }); 
  }*/

  /*useEffect(() => {
    runOneSignal();
  },[]);*/
  return (
    <>
      <div className="info_portrait">
        <h2>{t('general.rotate')}</h2>
        <img src={general_images['rotate.png']} alt={t('general.rotate_text')} />
      </div>
      <div className={"App "+settings.font}>
        <BrowserRouter>
          <AnimatedRoutes startSound={startSound} stopSound={stopSound} />
        </BrowserRouter>
        {name && name!=='' && (
          <>
            <audio loop autoPlay id="bgSound">
              <source src="" type="audio/mp3" />
            </audio>
          </>
        )}
      </div>
    </>
  )
  
}

//Wird angezeigt bei Ladezeiten
export default function WrappedApp() {
  const { t } = useTranslation();
  const loading_text = t('general.is_loading');
  return (
    <Suspense fallback={loading_text}>
      <App />
    </Suspense>
  );
};
