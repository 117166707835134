export const quiz_de =  {
  "general": {
    "title": "Recht auf Erhalt von angemessener Therapie und psychologischer Betreuung",
    "intro": "Löse das Quiz!",
    "finishButton": "Antworten prüfen",
    "nrQuestions": 4,
    "maxPoints": 20,
    "resultTitle": "Geschafft!",
    "resultText": "Du hast <correctQuestions> von <questions> Fragen richtig beantwortet und damit <points> von <pointsMax> Punkten erreicht.",
    "button_back": "zurück",
    "button_forward": "nächste"
  },
  "questions": [
    {
      "question": "Was ist ist keine Therapie?",
      "picture": "",
      "answers": [
        "Physiotherapie",
        "Psychotherapie",
        "Computertherapie"
      ],
      "correctAnswer": [2],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Mit wem spricht ein Kind in einer Therapie?",
      "picture": "",
      "answers": [
        "mit einer Psychologin oder einem Psychologen",
        "mit einer Polizistin oder einem Polizisten",
        "mit einer Lehrerin oder einem Lehrer"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Was kann ein Kind in einer Therapie machen?",
      "picture": "",
      "answers": [
        "gemeinsam mit Freunden gamen",
        "über die Gefühle sprechen",
        "Hausaufgaben machen"
      ],
      "correctAnswer": [1],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Wie hilft eine Therapeutin oder ein Therapeut einem Kind?",
      "picture": "",
      "answers": [
        "er/sie hört gut zu",
        "er/sie macht Geschenke",
        "er/sie kontrolliert die Hausaufgaben"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
  ]
}