import { useState, useEffect } from 'react';
import { useUserContext } from '../../../hooks/useUserContext';
import { useTranslation } from 'react-i18next';
import ImageMap from '../../welten/ImageMap';
import "./Kindeswohl.css";

export default function Bekanntmachung({oldAndNewScore}) {
    const { t } = useTranslation();
    //variables for score and state updating
    const { games, updateGames, calculateScore, score, language } = useUserContext();
    const bekanntmachung = games.bekanntmachung;
    const max_points = games.bekanntmachung.points_max;

    //set up image map variables
    var imgSrc;
    switch(language) {
      case 'de':
        imgSrc = "Bekanntmachung.png";
        break;
      case 'fr':
        imgSrc = "Bekanntmachung_fr.png";
        break;
      case 'it':
        imgSrc = "Bekanntmachung_it.png";
        break;
    }
    const height = window.innerHeight*0.6;
    const imgWidth = (height/698)*720;
    const viewbox = "0 0 " + imgWidth.toString() + " " + height.toString();
    const factor = height/698;
    const imgMap = {
        imgSrc: imgSrc,
        areas: [
          {
            id: 1,
            name: 'bekanntmachung1',
            type: 'rect',
            x: 0,
            y: 0,
            width: 157,
            height: 235,
          },
          {
            id: 2,
            name: 'bekanntmachung2',
            type: 'rect',
            x: 296,
            y: 0,
            width: 112,
            height: 235,
          },
          {
            id: 3,
            name: 'bekanntmachung3',
            type: 'rect',
            x: 515,
            y: 0,
            width: 119,
            height: 235,
          },
          {
            id: 4,
            name: 'bekanntmachung4',
            type: 'rect',
            x: 0,
            y: 287,
            width: 157,
            height: 161,
          },
          {
            id: 5,
            name: 'nope',
            type: 'rect',
            x: 296,
            y: 287,
            width: 121,
            height: 161,
          },
          {
            id: 6,
            name: 'bekanntmachung5',
            type: 'rect',
            x: 515,
            y: 287,
            width: 150,
            height: 161,
          },
          {
            id: 7,
            name: 'bekanntmachung6',
            type: 'rect',
            x: 0,
            y: 473,
            width: 206,
            height: 225,
          },
          {
            id: 8,
            name: 'nope',
            type: 'rect',
            x: 296,
            y: 515,
            width: 112,
            height: 183,
          },
          {
            id: 9,
            name: 'bekanntmachung7',
            type: 'rect',
            x: 513,
            y: 514,
            width: 67,
            height: 183,
          }
        ],
    }

    const [wimmelbilder, setWimmelbilder] = useState({
        bekanntmachung1: false,
        bekanntmachung2: false,
        bekanntmachung3: false,
        bekanntmachung4: false,
        bekanntmachung5: false,
        bekanntmachung6: false,
        bekanntmachung7: false
    });

    const [text, setText] = useState(t('bekanntmachung.start_text'));

    const clicked = (event, name) => {
        if (name!=="nope") {
            setWimmelbilder(wimmelbilder => ({...wimmelbilder, [name]: true}));
            event.target.id="active";
            show_message("good");
        } else if (name === "nope") {
            event.target.id="wrong";
            show_message("bad");
        };
        
    };

    const [wimmelbild_msg, setWimmelbildMsg] = useState("");
    const show_message = (state) => {
        if (state === "good") {
            setWimmelbildMsg(t('bekanntmachung.correct'));
        } else if (state === "bad") {
            setWimmelbildMsg(t('bekanntmachung.wrong'));
        };
        document.getElementById("wimmelbild_message").style.display = "flex";
        setTimeout(() => document.getElementById("wimmelbild_message").style.display="none", 1000);
    };

    useEffect(() => {
        let finished_temp = true;
        for (const value of Object.values(wimmelbilder)) {
            if (value === false) {
                finished_temp = false;
            };
        };
        if (finished_temp === true) {
            setText(t('bekanntmachung.finish_text'));
            const bekanntmachung_new = { ...bekanntmachung, state: 'done', points_achieved: max_points };
            const games_new = { ...games, bekanntmachung: bekanntmachung_new };
            updateGames(games_new);
            const current_game = "bekanntmachung";
            let total_score = calculateScore(current_game);
            total_score += max_points;
            const oldScore = score;
            const newScore = total_score;
            oldAndNewScore(oldScore, newScore);
            //updateScore(total_score);
            setTimeout(() => {
                setWimmelbildMsg(t('bekanntmachung.finished'));
                document.getElementById("wimmelbild_message").style.display="flex";
                }
                , 1500);
        };
    },[wimmelbilder]);

    return (
        <div className="wimmelbild">
            <h2>{text}</h2>
            <div className="wrapper_wimmelbild" style={{ width: "100%", height: "65vh", overflowX: "auto", overflowY: "auto" }}>
                <ImageMap clicked={clicked} factor={factor} imgWidth={imgWidth} viewbox={viewbox} height={height} imgMap={imgMap} folder="game"/>
            </div>
            <div className="wimmelbild_message" id="wimmelbild_message">
                <p>{wimmelbild_msg}</p>
            </div>
        </div>
    )
}