import { image_importer } from "../../helper/helper_functions";

//import components
import { useEffect, useState } from "react";
import { useUserContext } from "../../hooks/useUserContext";
import ContentPlacer from "./ContentPlacer";
import ImageMap from "./ImageMap";
import AvatarIcon from "../AvatarIcon";

//import styles
import './welten.css';

//import for logging friendly analytics
import { useMatomo } from "@datapunt/matomo-tracker-react";

export default function Beteiligunsrechte({ height, clicked, startSound }) {
  useEffect(() => {
    //const backgroundSong = '../../songs/drop-it-124014.mp3';
    startSound(backgroundSong, false);
    //logging for friendly analytics
    trackPageView({
      documentTitle: 'Welt Beteiligungsrechte',
      href: '/Welt/beteiligungsrechte',
    })
    const new_position_x = games['beteiligung_schule'].position.x * factor + games['beteiligung_schule'].height * factor * 0.1;
    const new_position_y = games['beteiligung_schule'].position.y * factor - games['beteiligung_schule'].height * factor * 0.9;
    document.getElementById("walking_avatar").className = "is_walking_avatar_right";
    document.getElementById("walking_avatar").style.position = "absolute";
    document.getElementById("walking_avatar").style.left = new_position_x + "px";
    document.getElementById("walking_avatar").style.top = new_position_y + "px";
  }, []);

  const [currentGame, setCurrentGame] = useState('beteiligung_schule');
  useEffect(() => {
    walk_and_open(currentGame, "", 0, false, false)
  }, [height])

  //import images
  const scenes = image_importer(require.context('../../assets/scenes/beteiligungsrechte', false, /\.(png|jpe?g|svg)$/));
  var backgroundSong;
  if (window.location.hostname !== "localhost") {
    const bgSounds = image_importer(require.context('../../assets/mp3/bg', false, /\.(mp3)$/));
    backgroundSong = bgSounds['drop-it-124014.mp3']
  } else {
    backgroundSong = '../../songs/drop-it-124014.mp3';
  }

  //initialize function for logging friendly analytics
  const { trackPageView } = useMatomo();

  //initialize variable for user data
  const { games } = useUserContext();

  //initialize basic variables
  const imgSrc = "Weltall_Welt.png";
  const imgWidth = (height / 1280) * 3840;
  const viewbox = "0 0 " + imgWidth.toString() + " " + height.toString();
  const factor = height / 1280;
  const imgMap = {
    imgSrc: imgSrc,
  }

  //set up var for contentPlacer
  const contentBeteiligungSchule = games['beteiligung_schule'];
  const sceneBeteiligungSchule = scenes[contentBeteiligungSchule.src];
  const contentBeteiligungKrise = games['beteiligung_krise'];
  const sceneBeteiligungKrise = scenes[contentBeteiligungKrise.src];
  const contentBeteiligungPolitik = games['beteiligung_politik'];
  const sceneBeteiligungPolitik = scenes[contentBeteiligungPolitik.src];
  const contentBeteiligungFamilie = games['beteiligung_familie'];
  const sceneBeteiligungFamilie = scenes[contentBeteiligungFamilie.src];
  const contentInformationsfreiheit = games['informationsfreiheit'];
  const sceneInformationsfreiheit = scenes[contentInformationsfreiheit.src];
  const contentReligionsfreiheit = games['religionsfreiheit'];
  const sceneReligionsfreiheit = scenes[contentReligionsfreiheit.src];
  const contentUmsetzung = games['umsetzung'];
  const sceneUmsetzung = scenes[contentUmsetzung.src];
  const contentBekanntmachung = games['bekanntmachung'];
  const sceneBekanntmachung = scenes[contentBekanntmachung.src];
  const contentSexuelleSelbstbestimmung = games['sexuelle_selbstbestimmung'];
  const sceneSexuelleSelbstbestimmung = scenes[contentSexuelleSelbstbestimmung.src];

  //function for avatar-walk and open content
  const walk_and_open = (name, sound, pages, video, open = true) => {
    setCurrentGame(name);
    const old_position_x = document.getElementById("walking_avatar").style.left;
    const data = games[name];
    let new_position_x = data.position.x * factor + data.height * factor * 0.2;
    const new_position_y = data.position.y * factor - data.height * factor * 0.9;
    if (Number(old_position_x.substring(0, old_position_x.lastIndexOf("p"))) > new_position_x) {
      document.getElementById("walking_avatar").className = "is_walking_avatar_left";
      new_position_x = data.position.x * factor + data.height * factor * 0.2;
    } else {
      document.getElementById("walking_avatar").className = "is_walking_avatar_right";
      new_position_x = data.position.x * factor + data.height * factor * 0.1;
    }
    document.getElementById("walking_avatar").style.position = "absolute";
    document.getElementById("walking_avatar").style.left = new_position_x + "px";
    document.getElementById("walking_avatar").style.top = new_position_y + "px";
    if (open === true) {
      if (Math.abs(Number(old_position_x.substring(0, old_position_x.lastIndexOf("p"))) - new_position_x) > 1) {
        setTimeout(() => clicked(name, sound, pages, video), 1300);
      } else {
        clicked(name, sound, pages, video);
      }
    }

  }

  return (
    <div className="background-image" style={{ maxWidth: '100%' }}>
      <div id="walking_avatar">
        <AvatarIcon />
      </div>
      <ContentPlacer data={contentBeteiligungSchule} factor={factor} clicked={walk_and_open} sound={true} pages={1} id="1" scene={sceneBeteiligungSchule} video={false} />
      <ContentPlacer data={contentBeteiligungKrise} factor={factor} clicked={walk_and_open} sound={false} pages={2} id="2" scene={sceneBeteiligungKrise} video={false} />
      <ContentPlacer data={contentBeteiligungPolitik} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="3" scene={sceneBeteiligungPolitik} video={false} />
      <ContentPlacer data={contentBeteiligungFamilie} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="4" scene={sceneBeteiligungFamilie} video={false} />
      <ContentPlacer data={contentInformationsfreiheit} factor={factor} clicked={walk_and_open} sound={false} pages={2} id="5" scene={sceneInformationsfreiheit} video={false} />
      <ContentPlacer data={contentReligionsfreiheit} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="6" scene={sceneReligionsfreiheit} video={false} />
      <ContentPlacer data={contentUmsetzung} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="7" scene={sceneUmsetzung} video={false} />
      <ContentPlacer data={contentBekanntmachung} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="8" scene={sceneBekanntmachung} video={false} />
      <ContentPlacer data={contentSexuelleSelbstbestimmung} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="9" scene={sceneSexuelleSelbstbestimmung} video={false} />
      <ImageMap clicked={clicked} factor={factor} imgWidth={imgWidth} viewbox={viewbox} height={height} imgMap={imgMap} folder="beteiligungsrechte" />
    </div>
  )
}
