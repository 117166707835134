export const quiz_it =  {
  "general": {
    "title": "Diritto alla realizzazione dei diritti dell'infanzia e dell'adolescenza ",
    "intro": "In che modo i bambini possono rivendicare i loro diritti? ",
    "finishButton": "Controllare le risposte",
    "nrQuestions": 5,
    "maxPoints": 25,
    "resultTitle": "ce l'hai fatta!",
    "resultText": "Avete risposto correttamente a <correctQuestions> domande su <questions> e quindi avete ottenuto <points> punti su <pointsMax>.",
    "button_back": "indietro",
    "button_forward": "prosegui"
  },
  "questions": [
    {
      "question": "Nel consiglio degli alunni o nel consiglio di classe?",
      "picture": "klassenrat.png",
      "answers": [
        "Sì",
        "No"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Nel parlamento dei bambini e dei giovani del comune?",
      "picture": "kinderparlament.png",
      "answers": [
        "Sì",
        "No"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "In un dialogo con un adulto?",
      "picture": "erwachsene.png",
      "answers": [
        "Sì",
        "No"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "In una manifestazione?",
      "picture": "demonstration.png",
      "answers": [
        "Sì",
        "No"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Con un avvocato?",
      "picture": "anwalt.png",
      "answers": [
        "Sì",
        "No"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
  ]
} 