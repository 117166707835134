import { image_importer } from "../../../helper/helper_functions";

import { useState, useEffect } from "react";
import { JigsawPuzzle } from "react-jigsaw-puzzle";
import { useUserContext } from '../../../hooks/useUserContext';
import { useTranslation } from 'react-i18next';
import "react-jigsaw-puzzle/lib/jigsaw-puzzle.css";
import "./BeteiligungSchule2.css";

export default function Loyalitaetskonflikt({oldAndNewScore}) {
  const { t } = useTranslation();
  const { games, updateGames, calculateScore, score, language } = useUserContext();

  //import images
  const img = image_importer(require.context('../../../assets/games/beteiligungsrechte/schule', false, /\.(png|jpe?g|jpg|svg)$/));
  var src;
  if (language.includes("de")) {
    src = img["Klassenrat.png"];
  } else if (language.includes("fr")) {
    src = img["Klassenrat_fr.png"];
  } else if (language.includes("it")) {
    src = img["Klassenrat_it.png"];
  } else {
    src = img["Klassenrat.png"];
  }


  //variables for updating score and state
  const puzzle = games.beteiligung_schule;
  const max_points = games.beteiligung_schule.points_max;

  //Code for setting Title
  const [text, setText] = useState(t('beteiligung_schule.start_text'));

  //when puzzle is finished
  const set = () => {
      setText(t('beteiligung_schule.finish_text'));
      setRunning(false);
      //console.log(time);
      const puzzle_new = { ...puzzle, state: 'done', points_achieved: max_points };
      const games_new = { ...games, beteiligung_schule: puzzle_new };
      updateGames(games_new);
      const current_game = "beteiligung_schule";
      let total_score = calculateScore(current_game);
      total_score += max_points;
      const oldScore = score;
      const newScore = total_score;
      oldAndNewScore(oldScore, newScore);
      //updateScore(total_score);
      document.getElementById("msg").style.visibility = "visible";
  };

  //Code for Timer
  const [time, setTime] = useState(0);
  const [running, setRunning] = useState(true);

  useEffect(() => {
    let interval;
    if (running) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
      }, 10);
    } else if (!running) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [running]);

  return (
    <div className="puzzle">
      <h2>{text}</h2>
      <p>{t('beteiligung_schule.explanation')}</p>
      {/*<div className="numbers">
        <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}:</span>
        <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}:</span>
        <span>{("0" + ((time / 10) % 100)).slice(-2)}</span>
      </div>*/}
      <JigsawPuzzle
        imageSrc={src}
        rows={3}
        columns={2}
        onSolved={set}
        class-Name="jigsaw-puzzle"
      />
      <div className="msg_success" id="msg">
        <p>{t('beteiligung_schule.msg_success')}</p>
      </div>
    </div>
  )
}
