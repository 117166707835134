export const quiz_de =  {
  "general": {
    "title": "Recht auf Umsetzung der Kinderrechte",
    "intro": "Wie können Kinder ihre Rechte einfordern?",
    "finishButton": "Antworten prüfen",
    "nrQuestions": 5,
    "maxPoints": 25,
    "resultTitle": "Geschafft!",
    "resultText": "Du hast <correctQuestions> von <questions> Fragen richtig beantwortet und damit <points> von <pointsMax> Punkten erreicht.",
    "button_back": "zurück",
    "button_forward": "nächste"
  },
  "questions": [
    {
      "question": "Im Schüler:innenrat oder Klassenrat?",
      "picture": "klassenrat.jpg",
      "answers": [
        "Ja",
        "Nein"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Im Kinder- und Jugendparlament in der Gemeinde?",
      "picture": "kinderparlament.jpg",
      "answers": [
        "Ja",
        "Nein"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Im Gespräch mit jedem Erwachsenen?",
      "picture": "erwachsene.jpg",
      "answers": [
        "Ja",
        "Nein"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Auf einer Demonstration?",
      "picture": "demonstration.jpg",
      "answers": [
        "Ja",
        "Nein"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Mit einem Anwalt oder einer Anwältin?",
      "picture": "anwalt.jpg",
      "answers": [
        "Ja",
        "Nein"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
  ]
} 