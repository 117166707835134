export const quiz_it =  {
  "general": {
    "title": "Diritto a ricevere una terapia e un'assistenza psicologica adeguate",
    "intro": "risolvi il quiz!",
    "finishButton": "Controllare le risposte",
    "nrQuestions": 4,
    "maxPoints": 20,
    "resultTitle": "ce l'hai fatta",
    "resultText": "Avete risposto correttamente a <correctQuestions> domande su <questions> quindi avete ottenuto <points> punti su <pointsMax>.",
    "button_back": "indietro",
    "button_forward": "prosegui"
  },
  "questions": [
    {
      "question": "Cosa non è una terapia?",
      "picture": "",
      "answers": [
        "Terapia pittorica",
        "Psicoterapia",
        "Allenamento di calcio"
      ],
      "correctAnswer": [2],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Con chi parla un bambino in terapia?",
      "picture": "",
      "answers": [
        "Con uno psicologo",
        "con un agente di polizia",
        "con un insegnante"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Cosa può fare un bambino in terapia?",
      "picture": "",
      "answers": [
        "Giocare con gli amici",
        "parlare dei suoi sentimenti",
        "fare i compiti"
      ],
      "correctAnswer": [1],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Come può un terapeuta aiutare un bambino?",
      "picture": "",
      "answers": [
        "ascolta bene",
        "fa dei regali",
        "lo accompagna in classe"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
  ]
}